import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { SectionLayout } from "../../components/shared/SectionLayout";

export const Download = () => {
  return (
    <SectionLayout>
      <Box>
        <Text fontSize={["1rem", "1rem", "1.5rem"]}>Coming Soon...</Text>
      </Box>
      <Box>
        <Image
          src="/images/PocketDocketlogo.svg"
          alt="PocketDocket logo"
          width={["50vw", "50vw", "35vw"]}
          margin={["1rem 0rem", "1rem 0rem", "2rem 0rem"]}
        />
      </Box>
      <Box>
        <Image
          src="/icons/ios-download.svg"
          alt="ios download button"
          width={["25vw", "20vw", "15vw"]}
        />
      </Box>
      <Box>
        <Image
          src="/images/CTAiPhone.svg"
          alt="PocketDocket homescreen side profile"
          marginTop={["2rem", "2rem", "5rem"]}
          marginBottom={["1.5rem", "1.5rem", "2.5rem"]}
        />
      </Box>
      <Box>
        <Text textAlign="center" fontSize={["0.5rem", "0.5rem", "0.75rem"]}>
          All rights reserved. Our Privacy Policy governs the collection, use,
          and protection of your personal information when you interact with our
          services. We are committed to safeguarding your privacy and ensuring
          the security of your data. By using our services, you agree to the
          terms outlined in this policy. We reserve the right to update this
          policy periodically, and your continued use of our services
          constitutes acceptance of any changes. Rest assured, your privacy is
          our priority.
        </Text>
      </Box>
    </SectionLayout>
  );
};
