import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Heading,
  Text,
} from "@chakra-ui/react";
import { SectionLayout } from "../../components/shared/SectionLayout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { relative } from "path";

export const Testimonials = () => {
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow ${className}`}>
        <Image
          src="/images/prevArrow.svg"
          maxHeight={["50%", "70%", "100%"]}
          position="relative"
          right={["40%", "50%", "70%"]}
        />
      </div>
    );
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow ${className}`}>
        <Image
          src="/images/nextArrow.svg"
          maxHeight={["50%", "70%", "100%"]}
          position="relative"
          left={["40%", "50%", "70%"]}
        />
      </div>
    );
  }

  var settings = {
    dots: false,
    speed: 600,
    slidesToShow: 1,
    centerMode: false,
    infinite: true,
    accessibility: true,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: <SampleNextArrow to="next" />,
    prevArrow: <SamplePrevArrow to="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      style={{
        display: "flex",
        width: "100vw",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "10",
        padding: "8% 5%",
        backgroundColor: "#F39369",
      }}
    >
      <Box>
        <Heading fontSize={["2rem", "2rem", " 3rem"]} textAlign={"center"}>
          Don’t just take our word for it
        </Heading>
      </Box>
      <Box>
        <Image
          src="/images/BrandDivider2.png"
          paddingBottom={["2rem", "2rem", "4rem"]}
          paddingTop={"1rem"}
        />
      </Box>
      <Box slider-container width={["80%", "80%", "60%"]}>
        <Slider {...settings}>
          {[
            { image: "/images/Testimonial.png" },
            { image: "/images/Testimonial.png" },
            { image: "/images/Testimonial.png" },
            { image: "/images/Testimonial.png" },
          ].map((v, i) => {
            return (
              <div key={i}>
                <div
                  highlight-card
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img src={v.image} />
                </div>
              </div>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};
