import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { SectionLayout } from "../../components/shared/SectionLayout";

export const Features = () => {
  return (
    <SectionLayout
      justifyContent={[undefined, undefined, "center"]}
      bg="white"
      id="feature"
      pb="8%"
    >
      <Box>
        <Heading fontSize={["2rem", "2rem", " 3rem"]}>Features</Heading>
      </Box>
      <Box>
        <img src="/images/brandDivider.png" style={{ paddingTop: "1rem" }} />
      </Box>
      <Grid
        maxW="1100px"
        templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}
        rowGap={["2.5rem", "3.5rem", "5rem", "10rem"]}
        columnGap="7.5em"
        marginTop={["2rem", "2rem", "5rem"]}
      >
        {/* Feature 1 */}
        <Flex
          flexDir="column"
          justifyContent="center"
          alignSelf="center"
          order={{ base: 1, md: 0 }}
        >
          <Box justifySelf="center" alignSelf="center">
            <Image src="/images/Feature1.png" maxW="100%" maxH="100%" />
          </Box>
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="space-even"
          order={{ base: 2, md: 0 }}
        >
          <InfoItemLeft title="Go Paperless" textAlign="left">
            <Text
              fontWeight={"600"}
              paddingBottom={["0.5rem", "0.5rem", "1rem"]}
            >
              Ditch the clutter and the risk of lost dockets.
            </Text>
            <Text>
              With our app, every variable is tracked and accounted for,
              ensuring you're always paid for your hard work without the hassle
              of paper.
            </Text>
          </InfoItemLeft>
        </Flex>

        {/* Feature 2 */}
        <Flex
          flexDir="column"
          justifyContent="space-between"
          order={{ base: 4, md: 0 }}
        >
          <InfoItemRight title="Ensure Seamless Compliance" textAlign="left">
            <Text
              fontWeight={"600"}
              paddingBottom={["0.5rem", "0.5rem", "1rem"]}
            >
              Instant, on-the-spot docket approval.
            </Text>
            <Text>
              Streamline your workflow with digital signatures for immediate
              compliance and a robust, paperless trail that leaves nothing to
              chance.
            </Text>
          </InfoItemRight>
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignSelf="center"
          order={{ base: 3, md: 0 }}
        >
          <Box justifySelf="center" alignSelf="center">
            <Image src="/images/Feature2.png" maxW="100%" maxH="100%" />
          </Box>
        </Flex>

        {/* Feature 3 */}
        <Flex
          flexDir="column"
          justifyContent="center"
          alignContent="center"
          order={{ base: 5, md: 0 }}
        >
          <Box justifySelf="center" alignSelf="center">
            <Image src="/images/Feature3.png" maxW="100%" maxH="100%" />
          </Box>
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignSelf="center"
          order={{ base: 6, md: 0 }}
        >
          <InfoItemLeft
            title="Insights and Reports, Simplified"
            textAlign="left"
          >
            <Text
              fontWeight={"600"}
              paddingBottom={["0.5rem", "0.5rem", "1rem"]}
            >
              Transform data into action.
            </Text>
            <Text>
              With just a few clicks, generate comprehensive reports and
              insights. Our intuitive reporting tools make invoice creation and
              direct stakeholder communication effortlessly efficient.
            </Text>
          </InfoItemLeft>
        </Flex>

        {/* Feature 4 */}
        <Flex
          flexDir="column"
          justifyContent="center"
          alignContent="center"
          order={{ base: 8, md: 0 }}
        >
          <InfoItemRight title="Centralise Your Operations">
            <Text
              fontWeight={"600"}
              paddingBottom={["0.5rem", "0.5rem", "1rem"]}
            >
              Keep everything in sight, and in sync.
            </Text>
            <Text>
              Easily manage your clients and jobs within the app, ensuring each
              docket contains all necessary information for streamlined
              operations and peace of mind.
            </Text>
          </InfoItemRight>
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignSelf="center"
          order={{ base: 7, md: 0 }}
        >
          <Box justifySelf="center" alignSelf="center">
            <Image src="/images/Feature4.png" maxW="100%" maxH="100%" />
          </Box>
        </Flex>
      </Grid>
    </SectionLayout>
  );
};

const InfoItemLeft = ({ title, children, ...props }) => {
  return (
    <Flex flexDir="column" width="100%">
      <Box
        fontSize={["1.5em", "1.5em", "2.5rem"]}
        fontWeight="600"
        lineHeight="110%"
        paddingBottom={["1rem", "1rem", "1.5rem"]}
      >
        {title}
      </Box>
      <Box>
        <img
          src="images/brandDivider.png"
          // style={{ paddingBottom: "1.5rem", paddingTop: "1.5rem" }}
        />
      </Box>
      <Box
        fontSize="1.25em"
        paddingTop={["1rem", "1rem", "1.5rem"]}
        paddingBottom={["3rem", "0rem", "0rem"]}
      >
        {children}
      </Box>
    </Flex>
  );
};

const InfoItemRight = ({ title, children, ...props }) => {
  return (
    <Flex flexDir="column" width="100%" {...props}>
      <Box
        fontSize={["1.5em", "1.5em", "2.5rem"]}
        fontWeight="600"
        lineHeight="110%"
        paddingBottom={["1rem", "1rem", "1.5rem"]}
      >
        {title}
      </Box>
      <Box>
        <Image src="images/brandDivider.png" />
      </Box>
      <Box
        fontSize="1.25em"
        paddingTop={["1rem", "1rem", "1.5rem"]}
        paddingBottom={["3rem", "0rem", "0rem"]}
      >
        {children}
      </Box>
    </Flex>
  );
};
