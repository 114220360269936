import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Heading,
  Text,
} from "@chakra-ui/react";
import { SectionLayout } from "../../components/shared/SectionLayout";

const CONTENT_LIST = [
  { title: "Create your clients & jobs", subtitle: "lorum ipsum dolar amit" },
  {
    title: "Create labour dockets",
    subtitle: "Dont miss a variability etc etc",
  },
  { title: "Make it official", subtitle: "sign the docket" },
  {
    title: "Report & send",
    subtitle: "Send a single docket, or a report comprised of multiple",
  },
  { title: "Track your performance", subtitle: "lorum ipsum dolar amit" },
];

export const HowItWorks = () => {
  return (
    <SectionLayout bg="#FFF7E9" gap="26px">
      <Box>
        <Heading fontSize={["2rem", "2rem", " 3rem"]}>How It Works</Heading>
      </Box>
      <Box>
        <img
          src="/images/brandDivider.png"
          style={{ paddingBottom: "2.5rem" }}
        />
      </Box>
      <Grid
        width={["80%", "100", "100%"]}
        templateRows={["repeat(5,1fr)", "repeat(2,1fr)", "repeat(2, 1fr)"]}
        templateColumns={["repeat(1,1fr)", "repeat(1,1fr)", "repeat(6, 1fr)"]}
        gap={"1.5rem"}
        borderRadius="25%"
        maxW="1100px"
        paddingBottom={["3rem", "0rem", "0rem"]}
      >
        <GridItem
          rowSpan={1}
          colSpan={[1, 1, 2]}
          borderRadius="15px"
          bg="white"
          boxShadow="0px 7px 14px -6px rgba(0,0,0,0.4)"
          display="flex"
        >
          <Box
            marginY="5%"
            marginX="7.5%"
            display="flex"
            alignItems="center"
            flexDir="column"
            justifyContent={["center", undefined, "start"]}
          >
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              fontSize="1.5rem"
              fontWeight="600"
              bg="#FE8E38"
              borderRadius="50%"
              w="2.25rem"
              h="2.25rem"
              color="white"
              marginBottom="3%"
            >
              1
            </Flex>

            <Text
              textAlign="center"
              fontSize="1.5rem"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              Create your clients & jobs
            </Text>
            <Text
              textAlign="center"
              fontSize="1.25rem"
              fontWeight="400"
              lineHeight="150%"
            >
              With a simple tap of a button, create new clients and jobs.
            </Text>
          </Box>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={[1, 1, 2]}
          borderRadius="15px"
          bg="white"
          boxShadow="0px 7px 14px -6px rgba(0,0,0,0.4)"
          display="flex"
        >
          <Box
            margin="5%"
            display="flex"
            alignItems="center"
            flexDir="column"
            justifyContent={["center", "center", "start"]}
            width="100%"
          >
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              fontSize="1.5rem"
              fontWeight="600"
              bg="#FE8E38"
              borderRadius="50%"
              w="2.25rem"
              h="2.25rem"
              color="white"
              marginBottom="3%"
            >
              2
            </Flex>

            <Text
              textAlign="center"
              fontSize="1.5rem"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              Create labour dockets
            </Text>
            <Text
              textAlign="center"
              fontSize="1.25rem"
              fontWeight="400"
              lineHeight="150%"
            >
              Don’t miss variability and your labour dockets.
            </Text>
          </Box>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={[1, 1, 2]}
          borderRadius="15px"
          bg="white"
          boxShadow="0px 7px 14px -6px rgba(0,0,0,0.4)"
          display="flex"
        >
          <Box
            marginY="5%"
            marginX="7.5%"
            display="flex"
            alignItems="center"
            flexDir="column"
            justifyContent={["center", "center", "start"]}
            width="100%"
          >
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              fontSize="1.5rem"
              fontWeight="600"
              bg="#FE8E38"
              borderRadius="50%"
              w="2.25rem"
              h="2.25rem"
              color="white"
              marginBottom="3%"
            >
              3
            </Flex>

            <Text
              textAlign="center"
              fontSize="1.5rem"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              Make it official
            </Text>
            <Text
              textAlign="center"
              fontSize="1.25rem"
              fontWeight="400"
              lineHeight="150%"
            >
              Just sign the docket and make it official!
            </Text>
          </Box>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={[1, 1, 2]}
          colStart={[1, 1, 2]}
          borderRadius="15px"
          bg="white"
          boxShadow="0px 7px 14px -6px rgba(0,0,0,0.4)"
          display="flex"
        >
          <Box
            margin="5%"
            display="flex"
            alignItems="center"
            flexDir="column"
            justifyContent={["center", undefined, "start"]}
          >
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              fontSize="1.5rem"
              fontWeight="600"
              bg="#FE8E38"
              borderRadius="50%"
              w="2.25rem"
              h="2.25rem"
              color="white"
              marginBottom="3%"
            >
              4
            </Flex>

            <Text
              textAlign="center"
              fontSize="1.5rem"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              Report & send
            </Text>
            <Text
              textAlign="center"
              fontSize="1.25rem"
              fontWeight="400"
              lineHeight="150%"
            >
              Send a single docket or a report comprised of multiple stuff.
            </Text>
          </Box>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={[1, 1, 2]}
          colStart={[1, 1, 4]}
          borderRadius="15px"
          bg="white"
          boxShadow="0px 7px 14px -6px rgba(0,0,0,0.4)"
        >
          <Box
            margin="5%"
            display="flex"
            alignItems="center"
            flexDir="column"
            justifyContent={["center", undefined, "start"]}
          >
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              fontSize="1.5rem"
              fontWeight="600"
              bg="#FE8E38"
              borderRadius="50%"
              w="2.25rem"
              h="2.25rem"
              color="white"
              marginBottom="3%"
            >
              5
            </Flex>

            <Text
              textAlign="center"
              fontSize="1.5rem"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              Track your performance{" "}
            </Text>
            <Text
              textAlign="center"
              fontSize="1.25rem"
              fontWeight="400"
              lineHeight="150%"
            >
              Whenever you need some stats or metrics, you know where to go.{" "}
            </Text>
          </Box>
        </GridItem>
      </Grid>
    </SectionLayout>
  );
};

// const NumberedRow = ({ num, title, subtitle }) => {
//   return (
//     <Flex flexDir="row" gap="16px" alignItems="start">
//       <Flex
//         flexDir="column"
//         justifyContent="center"
//         alignItems="center"
//         fontSize="20px"
//         fontWeight="800"
//         bg="#FE8E38"
//         borderRadius="50%"
//         minW="50px"
//         w="50px"
//         h="50px"
//         color="white"
//         boxShadow="2px 7px 14px -6px rgba(0,0,0,0.75)"
//       >
//         {num}
//       </Flex>
//       <Flex flexDir="column">
//         <Box fontSize="20px">{title}</Box>
//         <Box fontSize="16px">{subtitle}</Box>
//       </Flex>
//     </Flex>

// Preivous Version

//     <SectionLayout bg="#FFF7E9" gap="26px">
//       <Heading fontSize={["2rem", "2rem", " 3rem"]}>How It Works</Heading>
//       <Flex flexDir="column" w="100%" h="100%" maxW="800px" alignItems="center">
//         <Grid
//           w="100%"
//           templateColumns={[
//             "repeat(1, 1fr)",
//             "repeat(1, 1fr)",
//             "repeat(2, 1fr)",
//           ]}
//           gap={8}
//         >
//           <Flex
//             flexDir="column"
//             justifyContent="space-between"
//             gap="5%"
//             py="5%"
//           >
//             {CONTENT_LIST.map((x, i) => (
//               <NumberedRow num={i + 1} title={x.title} subtitle={x.subtitle} />
//             ))}
//           </Flex>
//           <Flex flexDir="row" justifyContent="center">
//             <Image src="/images/mobile-how-it-works.png" w="300px" />
//           </Flex>
//         </Grid>
//       </Flex>
//     </SectionLayout>
//   );
// };

// const NumberedRow = ({ num, title, subtitle }) => {
//   return (
//     <Flex flexDir="row" gap="16px" alignItems="start">
//       <Flex
//         flexDir="column"
//         justifyContent="center"
//         alignItems="center"
//         fontSize="20px"
//         fontWeight="800"
//         bg="#FE8E38"
//         borderRadius="50%"
//         minW="50px"
//         w="50px"
//         h="50px"
//         color="white"
//         boxShadow="2px 7px 14px -6px rgba(0,0,0,0.75)"
//       >
//         {num}
//       </Flex>
//       <Flex flexDir="column">
//         <Box fontSize="20px">{title}</Box>
//         <Box fontSize="16px">{subtitle}</Box>
//       </Flex>
//     </Flex>
//   );
// };
