import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Text,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { SectionLayout } from "../../components/shared/SectionLayout";
import { color } from "framer-motion";

export const Main = () => {
  return (
    <Box
      bgImage={"url('/images/mainBackground.png')"}
      bgRepeat={"no-repeat"}
      backgroundSize={"100% 100%"}
    >
      <SectionLayout
        justifyContent={[undefined, undefined, "center"]}
        position="relative"
      >
        <Box>
          <Flex flexDir="column" justifyContent="center">
            <Flex flexDir="column" justifyContent="center">
              <img
                src={"/images/PocketDocketlogo.svg"}
                alt="PocketDocket logo"
                style={{
                  height: "1.5rem",
                }}
              />
              <Box textAlign={"center"}>
                <Heading
                  fontSize={["3rem", "3.5rem", " 4.5rem"]}
                  fontWeight={"bold"}
                  marginTop={["2rem", "3rem"]}
                >
                  Simplifying <br />
                  <span
                    style={{
                      color: "white",
                      backgroundColor: "#FF8E39",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                  >
                    Labour Dockets
                  </span>
                </Heading>
              </Box>
              <Box alignSelf={"center"} marginTop={"2rem"}>
                <img src="/images/brandDivider.png" style={{ width: "100%" }} />
              </Box>
              <Box
                textAlign={"center"}
                maxW={["70%", "70%", "60%"]}
                alignSelf={"center"}
                marginTop={"2rem"}
              >
                <Text
                  fontSize={["1rem", "1rem", " 1.5rem"]}
                  fontWeight={"medium"}
                >
                  Ditch the paper with Pocket
                  <span style={{ color: "#FF8E39" }}>Docket</span> and ensure
                  all your Labour Dockets are signed, stored, reported and paid
                  on time.
                </Text>
              </Box>
              <img
                src="/icons/ios-download.svg"
                style={{
                  height: "3.5rem",
                  marginTop: "3rem",
                }}
              />
            </Flex>
            <Flex justifyContent={"center"} marginTop={"5rem"}>
              <img
                src={"/images/mainHeroPhone.png"}
                alt="PocketDocket app homescreen"
                style={{
                  height: "50%",
                  alignSelf: "center",
                }}
              />
            </Flex>
          </Flex>
        </Box>
      </SectionLayout>
    </Box>
  );
};
